@import "~bootswatch/dist/litera/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/litera/bootswatch";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~apexcharts/src/assets/apexcharts";
@import "~choices.js/src/styles/choices";
@import "~datatables.net-bs5/css/dataTables.bootstrap5";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5";
@import "~datatables.net-responsive-bs5/css/responsive.bootstrap5";
@import "~datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5";
@import "~dragula/dist/dragula";
@import "~flatpickr/dist/flatpickr";
@import "~fullcalendar/main";
@import "~jsvectormap/dist/css/jsvectormap";
@import "~quill/dist/quill.bubble";
@import "~quill/dist/quill.snow";
@import "~simplebar/dist/simplebar";
@import "~notyf/notyf.min";

body {
  background-color: #f7f7f7;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

p {
  font-family: unset;
}

.header {
  background-color: #232c4d;
}

.header-appli {
  // mobile
  border: none;
  height: 200px;
  background: #232c4d url("../../../custom/images/bandeau_mobile.jpg") no-repeat;
}

.header-appli-login-info {
  color: white;
}

@media (min-width: 768px) {
  // tablette
  .header-appli {
    border: none;
    background: #232c4d url("../../../custom/images/bandeau_tablette.jpg") no-repeat;
    height: 200px;
  }
}

@media (min-width: 1199px) {
  .header-appli {
    border: none;
    background: #232c4d url("../../../custom/images/bandeau_pc.jpg") no-repeat;
    height: 200px;
  }
}

.footer {
  margin-top: 20px;
  background-color: #002a3f;
  padding: 20px !important;
  width: 100%;
}

.rgpd {
  font-size: 11px;
  color: grey;
}

.custom-file-label::after {
  content: "Parcourir" !important;
}